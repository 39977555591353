import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import PrivateLayout from "layouts/PrivateLayout";

import PageTitle from "components/PageTitle";

const BlankPage = ({ title, link, to, children }) => (
  <PrivateLayout metaTitle={title}>
    <PageTitle title={title} />
    <p>{children}</p>
    {link && (
      <Link style={{ paddingBottom: 30 }} to={to}>
        {link}
      </Link>
    )}
  </PrivateLayout>
);

BlankPage.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BlankPage;
