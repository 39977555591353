import React from "react";

import BlankPage from "templates/BlankPage";

const NotFoundPage = () => (
  <BlankPage title="Página no encontrada" link="Volver a home" to="/">
    Oops, parece que has intentado acceder a una página que no existe.
  </BlankPage>
);

export default NotFoundPage;
