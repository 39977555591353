import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { REGISTER_ROUTE, LOGIN_ROUTE } from "@routes";

import withAuth from "contexts/withAuth";
import Layout from "layouts/Layout";

import Logo from "components/Logo";
import PageTitle from "components/PageTitle";

const IS_ON_HOLIDAY_BREAK = false;
const IS_ON_SEASON_BREAK = false;
const ADMIN_EMAIL = "lavilaine.es@gmail.com";

const AuthProtected = ({
  user,
  checkingSession,
  isAuthenticated,
  children,
}) => {
  if (isAuthenticated) {
    if (IS_ON_HOLIDAY_BREAK && user !== null && user.email !== ADMIN_EMAIL) {
      return (
        <Layout metaTitle="Estamos de pausa">
          <PageTitle title="Estamos de pausa" />
          <p>
            LaVilaine Mayorista estará cerrado del 24 de Julio al 9 de Agosto.
            <br />
            <br />
            Gracias por su interés.
          </p>
        </Layout>
      );
    }

    if (IS_ON_SEASON_BREAK && user !== null && user.email !== ADMIN_EMAIL) {
      return (
        <Layout metaTitle="Estamos preparando la nueva colección">
          <PageTitle title="Estamos preparando la nueva colección" />
          <p>
            La colección primavera verano está por venir.
            <br />
            Respira, la colección 2023 será fantástica con muuuuchos colores.
            <br />
            Si estás en las Islas Canarias llámanos por teléfono al{" "}
            <b>651 639 581</b> (WhatsApp, FaceTime, Skype).
          </p>
        </Layout>
      );
    }

    return children;
  } else if (checkingSession) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Logo />
      </div>
    );
  }

  return (
    <Layout metaTitle="Página privada">
      <PageTitle title="Página privada" />
      <p>
        Está intentando acceder a una página exclusiva para miembros. Por favor{" "}
        <Link to={REGISTER_ROUTE}>cree una cuenta</Link>.
        <br />
        <br />
        Si ya tiene una cuenta, por favor{" "}
        <Link to={LOGIN_ROUTE}>inicie sesión</Link>
        .
        <br />
        <br />
        Si sigue viendo esta página significa que su cuenta no ha sido aceptada
        aún. Recibirá un correo electrónico cuando pueda acceder al sitio web.
        <br />
        <br />
        Gracias por su interés.
      </p>
    </Layout>
  );
};

AuthProtected.propTypes = {
  user: PropTypes.objectOf({ email: PropTypes.string.isRequired }),
  checkingSession: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default withAuth(AuthProtected);
