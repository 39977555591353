import React from "react";
import PropTypes from "prop-types";

import Layout from "layouts/Layout";

import AuthProtected from "components/AuthProtected";

const PrivateLayout = ({ layout: PropsLayout, children, ...layoutProps }) => (
  <AuthProtected>
    <PropsLayout {...layoutProps}>{children}</PropsLayout>
  </AuthProtected>
);

PrivateLayout.propTypes = {
  layout: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
PrivateLayout.defaultProps = {
  layout: Layout,
};

export default PrivateLayout;
